<template>
  <div class="downloadSection">
    <div class="tc title">
      <i class="el-icon-back iconBack cursor" @click="back"></i>{{$t('downloadSection')}}
    </div>
    <div class="content content_area" v-loading="loading">
      <div class="clearfix">
        <div
          v-for="(it, index) in downloadList"
          :key="index"
          class="item fl textOverflow cursor"
          @click="downloadChange(it)"
        >
          {{ it.name }}
        </div>
      </div>
      <noDataImg v-if="!loading && downloadList.length == 0"></noDataImg>
    </div>
  </div>
</template>
<script>
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
export default {
  metaInfo() {
    return {
      title: this.$t("downloadSection"),
    };
  },
  components: { isLoadingTip, isAllDataTip },
  data() {
    return {
      downloadList: [],
      loading: false,
      currentPage: 0,
      total: 0,
      dataTip: false,
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    async getList() {
      this.loading = true;
      try {
        let result = await this.$store.dispatch("API_index/downloadList", {
          source: this.PJSource,
          start: this.currentPage,
          nopage: 1,
          language: this.LOCALE == "en" ? 1 : 2,
        });
        if (result.success) {
          this.currentPage++;
          this.loading = false;
          this.downloadList = result.data;
          this.total = result.total;
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },

    downloadChange(item) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      } else {
        window.open(item.url);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.downloadSection {
  padding: 30px 0 60px;
  .title {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 24px;
    .iconBack {
      border: 1px solid #7d8695;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .content {
    min-height: 267px;
    background: #ffffff;
    padding: 40px 80px 60px;
    position: relative;
    .item {
      width: 50%;
      margin-bottom: 34px;
      font-size: 15px;
      font-weight: bold;
      &:hover {
        color: #ff5155;
      }
    }
  }
}
</style>